import * as Sentry from '@sentry/vue'

import type { Auth, AuthCode, User } from '~/types'

interface State {
  data: User | undefined
}

export const useAuthStore = defineStore('auth', {
  state: (): State => {
    return <State>{
      data: undefined
    }
  },
  getters: {
    isLogged: (state: State) => {
      return state.data !== undefined
    },
    name(): string {
      return this.firstLastName ?? this.data?.phone ?? ''
    },
    firstLastName: (state) => {
      const tmp: string[] = []
      if (state.data?.first_name) {
        tmp.push(state.data.first_name)
      }
      if (state.data?.last_name) {
        tmp.push(state.data.last_name)
      }
      return tmp.length ? tmp.join(' ') : undefined
    },
    balance: (state: State): number => {
      return state.data?.balance ?? 0
    }
  },
  actions: {
    init() {
      const { data } = useAuth()
      const user = data.value as unknown as User
      if (user) {
        this.data = user

        // Sentry.setUser({
        //   id: user.id,
        //   username: this.firstLastName,
        //   phone: user.phone,
        //   last_name: user.last_name,
        //   first_name: user.first_name,
        //   roles: user.roles,
        //   permissions: user.permissions
        // })
      }
    },
    auth(data?: any){
      return useAPI<Auth>('/v1/auth', {
        method: 'POST',
        body: data
      })
    },
    authCode(data?: any){
      return useAPI<AuthCode>('/v1/auth/code', {
        method: 'POST',
        body: data,
      })
    },
    async logout(){
      const { data, setToken } = useAuthState()

      await useAPI<AuthCode>('/v1/auth/logout', {
        method: 'POST',
      })

      setToken(null)
      data.value = undefined
      this.data = undefined
    }
  }
})
